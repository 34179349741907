import { default as Breadcrumbs } from '@jetshop/ui/Breadcrumbs';
import React, { useState } from 'react';
import styled from 'react-emotion';
import MaxWidth from '../Layout/MaxWidth';
import theme from '@jetshop/ui/utils/theme';
import Image from '@jetshop/ui/Image';
import { Above } from '@jetshop/ui/Breakpoints';

export const CategoryHeaderWrapper = styled(MaxWidth)`
  margin: 0 auto 20px auto;
`;

const CategoryImageWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0.5rem;
  ${({ theme }) => theme.below.lg} {
    flex-wrap: wrap;
    flex: none;
    display: block;
  }
  .image-wrap {
    width: 50%;
    height: auto;
    ${({ theme }) => theme.below.lg} {
      width: 100%;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
`;

const CategoryInfo = styled('div')`
  padding: 1rem;
  width: 100%;
  max-width: 50%;
  margin: 0 auto;
  align-self: center;
  text-align: center;
  letter-spacing: 0.5px;

  &.no-image {
    max-width: 900px;
  }
  p {
    margin-bottom: 1rem;
  }
  span {
    display: block;
    margin-bottom: 1rem;
  }

  img {
    max-width: 100%;
  }
  ${({ theme }) => theme.below.lg} {
    padding: 0;
    max-width: 100%;
  }

  .read-more {
    text-align: center;
    border-bottom: 2px solid black;
    padding-bottom: 3px;
    display: inline-block !important;
    margin: 20px auto 0 10px;
  }
`;

const CategoryName = styled('h1')`
  font-family: ${theme('fonts.body')};
  line-height: 1;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 10px;
  padding: 0 1rem;
  text-transform: uppercase;
  text-align: center;
  padding: 0;
  ${({ theme }) => theme.below.lg} {
    font-size: 2rem;
  }
`;

const CategoryContent = styled('div')`
  font-weight: 300;
  line-height: 22px;
  font-size: 0.9rem;
  padding: 0 1rem;
  text-align: center;
  h3,
  h4 {
    font-size: 1rem;
    font-weight: bold;
  }
  p {
    letter-spacing: 0.5px;
  }
  ${({ theme }) => theme.below.lg} {
    font-size: 12px;
    line-height: 1.5;
  }
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  justify-content: center;
  padding: 20px 20px;
  margin: 0;
  text-align: left;
  width: 100%;
  li,
  a {
    color: #a7a7a7;
  }
  li {
    letter-spacing: 1px;
  }
  a {
    text-decoration: none;
    transition: all ease 0.3s;
    &:hover {
      color: ${theme('colors.grey')};
    }
  }
  ${theme('below.lg')} {
    margin: 0;
    padding: 10px;
  }
`;

const Content = ({ category, content, noImage }) => {
  const [open, setOpen] = useState(false);
  const lengthLimit = 3;
  const contentWithoutHtml = content
    ? content?.replace(/(<([^>]+)>)/gi, '')
    : category?.content?.replace(/(<([^>]+)>)/gi, '');
  const sentences = contentWithoutHtml
    .split('.')
    .filter(sentence => sentence.length > 0);

  const shouldBeVisible = sentences.length > lengthLimit;

  const trimmed = sentences.slice(0, 3).join('.') + '.';
  const untrimmed = content || category?.content;

  return (
    <CategoryInfo
      className={`${noImage ? 'no-image' : 'image'} ${
        open ? 'open' : 'closed'
      }`}
    >
      <CategoryName data-testid="page-header">
        {category.mainHeader || category.name}
      </CategoryName>
      <CategoryContent
        data-testid="category-description"
        dangerouslySetInnerHTML={{
          __html: open ? untrimmed : trimmed
        }}
      />
      {shouldBeVisible && (
        <span className="read-more" onClick={() => setOpen(!open)}>
          {open ? 'Läs mindre' : 'Läs mer'}
        </span>
      )}
    </CategoryInfo>
  );
};

const CategoryHeader = ({ category, content, parents }) => {
  const breadcrumbProps = {
    breadcrumbText: category.breadcrumbText,
    parents
  };
  const isImage = category.images && category.images.length > 0;
  return (
    <CategoryHeaderWrapper>
      <StyledBreadcrumbs {...breadcrumbProps} />
      <>
        <Above breakpoint="lg">
          {matches =>
            matches ? (
              <>
                {isImage ? (
                  <CategoryImageWrapper>
                    <div className="image-wrap">
                      <img src={category.images[3].url} />
                    </div>
                    <Content
                      category={category}
                      content={content}
                      breadcrumbProps={breadcrumbProps}
                    />
                  </CategoryImageWrapper>
                ) : (
                  <Content
                    noImage={true}
                    category={category}
                    content={content}
                    breadcrumbProps={breadcrumbProps}
                  />
                )}
              </>
            ) : (
              <Content
                content={content}
                category={category}
                breadcrumbProps={breadcrumbProps}
              />
            )
          }
        </Above>
      </>
    </CategoryHeaderWrapper>
  );
};

export default CategoryHeader;
