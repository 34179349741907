import { useLocation } from 'react-router';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'react-emotion';
import { StyledBreadcrumbs } from '../CategoryPage/CategoryHeader';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .inner {
    width: 100%;
    max-width: 1200px;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header {
    padding: 1rem;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    align-self: center;
    text-align: center;
    letter-spacing: 0.5px;
    margin-bottom: 2rem;

    h1 {
      font-family: 'Avenir LT W01 45 Book';
      line-height: 1;
      font-size: 2rem;
      font-weight: bold;
      letter-spacing: 1px;
      margin-bottom: 10px;
      padding: 0 1rem;
      text-transform: uppercase;
      text-align: center;
      padding: 0;
    }
    p {
      font-weight: 300;
      line-height: 22px;
      font-size: 0.9rem;
      padding: 0 1rem;
      text-align: center;
    }
  }
`;

declare const window: {
  avardaPayFrameInit?: ((initFlags: PayFrameInitFlags) => void) & {
    q?: [PayFrameInitFlags][];
  };
  avardaPayFrameInstance?: PayFrameInstance;
};

type Language = 'sv' | 'fi' | 'da' | 'no' | 'de' | 'en';

type PayFrameInitFlags = {
  rootNode: HTMLElement;
  siteKey: string;
  language?: Language;
};

type PayFrameInstance = {
  unmount: () => void;
  changeLanguage: (language: Language) => void;
};

// Constants for PayFrame
const PAY_FRAME_ROOT_ELEMENT_ID = 'pay-frame';
const PAY_FRAME_SCRIPT_ID = 'pay-frame-script';
const PAY_FRAME_SITE_KEY_PROD = 'ff3023f8-fed3-458e-852a-622efaaa0a72';
const PAY_FRAME_SITE_KEY_STAGING = '25da5078-a083-4216-bf3e-36a849addcdd';

export function PayFrame({ staging }: { staging: boolean }) {
  const payFrameDivRef = useRef(null);

  useEffect(() => {
    if (!payFrameDivRef.current) return;

    loadPayFrameScript();
    initPayFrame(payFrameDivRef.current, staging);
    return () => {
      if (
        window.avardaPayFrameInstance &&
        window.avardaPayFrameInstance.unmount
      ) {
        window.avardaPayFrameInstance.unmount();
      }
      const scriptElement = document.getElementById(PAY_FRAME_SCRIPT_ID);
      if (scriptElement) {
        scriptElement.remove();
      }
    };
  }, []);

  return <div id={PAY_FRAME_ROOT_ELEMENT_ID} ref={payFrameDivRef} />;
}

const initPayFrame = (rootNode: HTMLElement, staging: boolean) => {
  try {
    if (!window.avardaPayFrameInit)
      throw new Error('avardaPayFrameInit function not found on the window');

    window.avardaPayFrameInit({
      rootNode,
      siteKey: staging ? PAY_FRAME_SITE_KEY_STAGING : PAY_FRAME_SITE_KEY_PROD,
      language: 'sv'
    });
  } catch (error) {
    console.error(error);
  }
};

const loadPayFrameScript = () => {
  if (document.getElementById(PAY_FRAME_SCRIPT_ID)) return;

  const script = document.createElement('script');
  script.id = PAY_FRAME_SCRIPT_ID;
  script.async = true;
  script.type = 'module';
  script.src =
    'https://pay-frame.stage.avarda.com/cdn/pay-frame.js?ts=' + Date.now();

  window.avardaPayFrameInit =
    window.avardaPayFrameInit ||
    function(...args) {
      (window.avardaPayFrameInit.q = window.avardaPayFrameInit.q || []).push(
        args
      );
    };

  document.body.prepend(script);
};

const InvoicePage = () => {
  const [staging, setStaging] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (typeof window.location !== 'undefined') {
        setStaging(
          window.location.href.includes('clnathletics.dev.jetshop.se')
        );
      }
    }

    setLoaded(true);
  }, []);

  console.log('staging', staging);

  const breadcrumbProps = {
    breadcrumbText: 'Faktura',
    parents: []
  };

  return (
    <Wrapper>
      <div className="inner">
        <StyledBreadcrumbs {...breadcrumbProps} />
        <div className="header">
          <h1>Betala din faktura</h1>
          <p>Betala din faktura här</p>
        </div>
        {loaded && <PayFrame staging={staging} />}
      </div>
    </Wrapper>
  );
};

export default InvoicePage;
